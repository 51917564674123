import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { colors } from '../../../styles/atoms/colors';

import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';

import Link from '../../../utils/Link';

import {
  Header,
  Buttons,
  Container,
  Metrics,
  Item,
  Image,
  Overlay,
  Logo,
  Text,
} from '../styles/UseCase-Vertical.styled';
import Icon from '../../../styles/atoms/icons';

const UseCaseVertical = ({
  id,
  eyebrow = 'Case Study',
  heading,
  primaryButton,
  secondaryButton,
  metrics,
  image,
  logo,
  quote,
  link,
  bgColorString,
}) => (
  <Section id={id} bgColor={colors.base[bgColorString]}>
    <Header>
      <p className="kicker">{eyebrow}</p>
      {heading && <h2 className="heading">{heading}</h2>}
      <Buttons>
        {primaryButton?.url && (
          <CallToAction
            variant="primary"
            link={primaryButton?.url}
            value={primaryButton?.label}
            size="lg"
          />
        )}
        {secondaryButton?.url && (
          <CallToAction
            variant="tertiary"
            link={secondaryButton?.url}
            value={secondaryButton?.label}
            size="lg"
          />
        )}
      </Buttons>
    </Header>
    <Container>
      <Metrics>
        {metrics?.map(({ id: metricsId, heading: metricsHeading, subhead }) => (
          <Item key={metricsId}>
            {metricsHeading && (
              <h3 className="item-heading">{metricsHeading}</h3>
            )}
            {subhead && <p className="item-subhead">{subhead}</p>}
          </Item>
        ))}
      </Metrics>
      <Image>
        {image?.gatsbyImageData && (
          <GatsbyImage
            image={image?.gatsbyImageData}
            loading="lazy"
            alt={image?.alt}
          />
        )}
        <Logo>
          {logo?.gatsbyImageData && (
            <GatsbyImage
              image={logo?.gatsbyImageData}
              loading="lazy"
              alt={logo?.alt}
            />
          )}
        </Logo>
        <Overlay className="gradient-overlay" />
        <Text className="text-block">
          {quote && <blockquote className="quote">{quote}</blockquote>}
          {link?.url && (
            <Link to={link?.url}>
              {link?.label}
              <Icon id="right-arrow-light" isImage />
            </Link>
          )}
        </Text>
      </Image>
    </Container>
  </Section>
);

export default UseCaseVertical;
