import styled from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import shadow from '../../../styles/atoms/shadows';

export const Header = styled.header`
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  max-width: 870px;
  margin-bottom: 48px;

  .kicker {
    ${font('overline', 'sm', '700')}
    color: ${colors.primary[500]};
  }

  .heading {
    ${font('display', 'md', '700')}
    color: ${colors.gray[900]};

    ${atMinWidth.sm`
       ${font('display', 'lg', '700')}
    `}

    ${atMinWidth.lg`
       ${font('display', 'xl', '700')}
    `}
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  width: 100%;

  ${atMinWidth.sm`
    flex-direction: row;
  `}

  .btn {
    width: 100%;

    ${atMinWidth.sm`
      width: auto;
    `}
  }

  .btn-outline-gray {
    border: 1px solid ${colors.gray[300]};
    background: transparent;
    color: ${colors.gray[700]};

    &:hover {
      background: ${colors.gray[50]};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;

  ${atMinWidth.md`
    flex-direction: row;
  `}
`;

export const Metrics = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  flex: 1 0 33%;

  ${atMinWidth.lg`
    gap: 32px;
  `}
`;

export const Item = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;

  .item-heading {
    position: relative;
    ${font('text', 'lg', '700')}
    color: ${colors.gray[900]};
    padding-left: 16px;

    ${atMinWidth.lg`
      ${font('text', 'xl', '700')}
    `}

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0px;
      width: 2px;
      height: 24px;
      background: ${colors.primary[500]};
    }
  }

  .item-subhead {
    ${font('text', 'sm', '400')}
    color: ${colors.gray[600]};
    padding-left: 16px;

    ${atMinWidth.lg`
      ${font('text', 'lg', '400')}
    `}
  }
`;

export const Image = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 2.6%,
    rgba(0, 0, 0, 0) 29.35%
  );
  ${shadow('xxl')}
  border-radius: 8px;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  z-index: 1;
  opacity: 0;
  transition: all 300ms ease-in-out;
  background: linear-gradient(
      0deg,
      rgba(1, 29, 186, 0.6),
      rgba(1, 29, 186, 0.6)
    ),
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 2.6%,
      rgba(0, 0, 0, 0) 29.35%,
      rgba(0, 0, 0, 0) 73.44%,
      rgba(0, 0, 0, 0.7) 95.83%
    );

  ${Image}:hover & {
    opacity: 1;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  max-width: min(60%, 300px);
  height: auto;
  z-index: 2;
  padding: 24px 0px 0px 24px;
`;

export const Text = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  z-index: 2;
  padding: 0px 24px 24px;
  transition: transform 300ms ease-in, opacity 200ms ease-out;
  transform: translateY(20%);

  ${Image}:hover & {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 300ms ease-in, opacity 300ms ease-in 100ms;
  }

  .quote {
    max-width: 674px;
    ${font('display', 'xs', '500')}
    color: ${colors.base.white};
    margin-bottom: 16px;

    ${atMinWidth.lg`
      ${font('display', 'md', '500')}
      margin-bottom: 32px;
    `}
  }

  a {
    ${font('text', 'sm', '700')}
    color: ${colors.base.white};

    ${atMinWidth.lg`
      ${font('text', 'lg', '700')}
    `}

    svg {
      transform: translateY(3px);
      margin-left: 8px;
      height: 24px;
      width: 22px;
      transition: 0.3s ease-in;
    }

    &:hover {
      color: ${colors.base.white};
      text-decoration: none;

      svg {
        margin-left: 13px;
      }
    }
  }
`;
